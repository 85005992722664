<template>
	<div id="userManagement">
		<!--tabs-->
		<div>
			<b-tabs content-class="mt-3">
				<!--User list tab-->
				<b-tab title="Users" active>
					<div id="userScreen" class="userManagement_screen">
						<!--Search bar-->
						<div class="userScreen__topRow">
							<input
								v-on:input="debounceInput"
								placeholder="Search for users"
								type="search"
								class="userScreen__searchInput"
								v-model="searchInput"
							>
							<!-- Check show disabled users -->
							<b-form-checkbox size="md" v-model="disableCheck">
								Show Disabled Accounts
							</b-form-checkbox>
							<!--Add user icon button-->
							<b-button 
								v-b-modal="'addUserModal'" 
								variant="primary" 
								size="sm"
								class="userScreen__addBtn"
							> Add User </b-button>
						</div>

						<!--Card list-->
						<div id="userList" class="userManagement_list scroll">
							<div v-if="users == null">
								<CardSkeleton />
							</div>

							<div v-else v-for="(user, uid) in users" :key="uid">
								<UserCard v-show="disableCheck | !user.disabled" :user="user"/>
							</div>
						</div>
					</div>
				</b-tab>

				<!--Group list tab-->
				<b-tab title="Groups">
					<div id="groupsScreen" class="userManagement_screen">
						<!-- Add group icon button-->
						<b-button v-b-modal.addGroupModal size="sm" variant="primary"> Add Group </b-button>

						<!-- Card list -->
						<div id="groupList" class="userManagement_list scroll">
							<div v-if="groups == null">
								<CardSkeleton />
							</div>

							<div v-else v-for="(group, gid) in groups" :key="gid">
								<GroupCard  :group="group" />
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>

		<div>
			<b-toast id="userMgmt_error" variant="danger" title="User Management Error">
				{{ errMsg }}
			</b-toast>
			<b-toast id="userMgmt_success" variant="primary" title="User Management Success">
				{{ sucMsg }}
			</b-toast>
		</div>

		<!--modals / menus-->
		<UserPassResetModal @success="showSuccessToast" @error="showErrorToast" :farm="farm"/>
		<AddUserModal @success="showSuccessToast" @error="showErrorToast" :farm="farm"/>
		<EnableUserModal @success="showSuccessToast" @error="showErrorToast" :farm="farm"/>
		<DisableUserModal @success="showSuccessToast" @error="showErrorToast" :farm="farm"/>
		<EditGroupModal @success="showSuccessToast" @error="showErrorToast" :farm= "farm"/>
		<EditUsersInGroupModal @success="showSuccessToast" @error="showErrorToast" :users="this.users" :farm= "farm" />
		<DeleteGroupModal @success="showSuccessToast" @error="showErrorToast" :farm= "farm" />
		<AddGroupModal @success="showSuccessToast" @error="showErrorToast" :farm= "farm" />

	</div>
</template>

<script>
import axios from "axios";
import CardSkeleton from "../components/userManagement/CardSkeleton.vue";
import UserCard from "../components/userManagement/UserCard.vue";
import GroupCard from "../components/userManagement/GroupCard.vue";
import UserPassResetModal from '../components/userManagement/UserPassResetModal.vue';
import AddUserModal from "../components/userManagement/AddUserModal.vue";
import EditGroupModal from '../components/userManagement/EditGroupModal.vue';
import EditUsersInGroupModal from '../components/userManagement/EditUsersInGroupModal.vue';
import DeleteGroupModal from "../components/userManagement/DeleteGroupModal.vue";
import AddGroupModal from "../components/userManagement/AddGroupModal.vue";
import EnableUserModal from "../components/userManagement/EnableUserModal.vue";
import DisableUserModal from "../components/userManagement/DisableUserModal.vue";

export default {
	name : "UserMagement",
	props: ["farm"],
	data() {
		return {
			users: null,
			groups: null,
			user : this.$store.state.user,
			searchInput: "",
			disableCheck: false,
			errMsg: "",
			sucMsg: "",
			timeout: null
		}
	},
	methods : {
		showErrorToast(msg, rfsh=true){
			this.errMsg = msg;
			this.$bvToast.show('userMgmt_error');
			if(rfsh){
				this.getUsers(this.searchInput);
				this.getGroups(this.farm);
			}
		},
		showSuccessToast(msg){
			this.sucMsg = msg;
			this.$bvToast.show('userMgmt_success');
			this.getUsers(this.searchInput);
			this.getGroups(this.farm);
		},
		getUsers(searchInput){
			this.users = null;
			axios.get(`/userManagement/user?usernameQuery=${searchInput}`)
			.then((res) => {
				this.users = res.data;
				// console.log(this.users);
			})
			.catch((error) => {
				console.error(error);
				this.showErrorToast(error, false);
			})
		},
		getGroups(farm){
			this.groups = null;
			axios.get(`/${farm}/group`)
			.then((res) => {
				this.groups = res.data;
				// console.log(this.groups);
			})
			.catch((error) => {
				console.error(error);
				this.showErrorToast(error, false);
			})
		},
		debounceInput: function() {
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.getUsers(this.searchInput);
			}, 1000);
		}
	},
	mounted(){
		this.getUsers(this.searchInput);
		this.getGroups(this.farm);
	},
	watch : {
		farm(){
			this.getUsers(this.searchInput);
			this.getGroups(this.farm);
		}
	},
	destroyed(){
		clearTimeout(this.timeout);
	},
	components : {
		UserCard,
		GroupCard,
		CardSkeleton,
		UserPassResetModal,
		AddUserModal,
		EditGroupModal,
		EditUsersInGroupModal,
		DeleteGroupModal,
		AddGroupModal,
		EnableUserModal,
		DisableUserModal
	}
}
</script>

<style>
.userScreen__searchInput{
	padding: 0px 10px;
	border-radius: 5px;
}

.userScreen__topRow {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

.userScreen__topRow * {
	margin: 5px 5px;
}

.userManagement_screen{
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 0px;
}

.userManagement_list {
	max-height: 90vh;
	overflow: auto;
	padding: 10px 20px;
	margin: 10px 0;
}

@media only screen and (max-width: 425px) {
	.userManagement_list {
		padding: 0;
		max-height: none;
		overflow-x: visible;
		overflow-y: visible;
	}
}

.userManagement_card {
	padding: 10px;
	margin: 20px 0;
	text-align: left;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;	
}

.userManagement_card.disabled {
	background-color: lightgray;
}

.userManagement_card__info-col {
	display: flex;
	flex-direction: column;
	justify-content: left;
	
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-white-space: nowrap;
	overflow: hidden;
}

.userManagement_card__info-col * {
	margin: 2px 5px;
}

.userManagement_card__info-col .disabled {
	border-radius: 5px;
	padding: 2px 5px;
	font-family: "courier-new", "monospace";
	color: white;
	background-color: red;
}

.userManagement_card__btn-col {
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	padding-right: 0;
}

.userManagement_card__btn-col * {
	margin: 5px 5px 5px 5px;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.userManagement_card {
		margin: 20px 0;
		flex-direction: column;
	}

	.userManagement_card__btn-col {
		/* display: none; */
		/* flex-direction: column; */
		justify-content: center;
	}

}

.userManagement__modal {
	display: none;
}

.userManagement__modal__footer {
	display: flex;
	flex-direction: row;
	justify-content: right;
}

button:focus + .userManagement__modal {
	display: block;
	background-color: whitesmoke;
	position: absolute;
	width: 69px;
	height: 69px;
	top: 0;
	left : 0;
}

</style>