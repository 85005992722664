<template>
	<div id="userCard">
		<div class="userManagement_card box-shadow-1" :class="{'disabled': user.disabled}">
			<div class="userManagement_card__info-col">
				<div><i class="fas fa-user"></i> {{ user.username }} <span v-show="user.disabled" class="disabled">DISABLED</span></div>
				<div><i class="fas fa-at"></i> {{ user.email }}</div>
				<div><i class="fas fa-clock"></i> {{ isNaN(Date.parse(user.lastlogin)) ? "-" : new Date(user.lastlogin).toLocaleDateString()}}</div>
			</div>
			
			<div class="userManagement_card__btn-col" v-show="user.username != cUser">
				<b-button
					v-b-modal.userPassResetModal
					@click="setUserToEdit(user)"
					size="sm"
					variant="primary"
					v-show="!user.disabled"
				> <i class="fas fa-key"></i> </b-button>
				<b-button
					v-b-modal.enableUserModal
					v-show="user.disabled"
					@click="setUserToEdit(user)"
					size="sm"
					variant="warning"					
				> 
					<i class="fas fa-user"></i> 
				</b-button>
				<b-button
					v-b-modal.disableUserModal
					v-show="!user.disabled" 
					@click="setUserToEdit(user)"
					size="sm"
					variant="danger"					
				> 
					<i class="fas fa-user-slash"></i> 
				</b-button>
			</div>
		</div>
	
	</div>
	
</template>

<script>
export default {
	name: "UserCard",
	props : {
		user : {type: Object}
	},
	data(){
		return {
			cUser: localStorage.getItem("user")
		}
	},
	methods : {
		setUserToEdit(user){
			this.$store.commit('setUserToEdit', user);
		}
	}
}
</script>

<style>
</style>