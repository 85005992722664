<template>
	<b-modal
		id="deleteGroupModal"
		:hide-footer="true"
		v-if="group"
		:title="`Delete group '${group?.name}'`"
	>
		<p>
			Are you sure you want to delete group `{{ group?.name }}`?
			This will remove the group from {{ farm }}.
			May cause associated users to lose access to FutureOS functions for {{ farm }}
		</p>

		<b-form @submit="onDeleteConfirm">
			<p>Please type in the group name to confirm user deletion </p>
			<b-form-input
				id="usernameInput"
				v-model="form.groupname"
				type="text"
				:placeholder="group?.name"
				:pattern="group?.name"
				required
			></b-form-input>

			<hr />

			<div class="userManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" variant="danger" size="sm" :disabled="reqStatus">Delete</b-button>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
import axios from 'axios';
import store from '../../store';
export default {
	name: "DeleteGroupModal",
	props : {
		farm : {type: String},
	},
	data(){
		return {
			form: { groupname: "" },
			reqStatus: false
		}
	},
	computed:{
		group: function(){ return store.state.groupToManage; }
	},
	methods : {
		onDeleteConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			axios.delete(`/${this.farm}/group/${this.group._id}`)
			.then((response) => {
				this.$store.commit('setGroupToManage', null);
				const res_grp_name = response.data.name;
				this.$emit('success', `deleted group ${res_grp_name} in ${this.farm}`);
				this.$bvModal.hide("deleteGroupModal");
				this.reqStatus = false;
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', err.response.data.error);
				this.$bvModal.hide("deleteGroupModal");
				this.reqStatus = false;
			});
		}
	},
	watch : {
		group(){
			this.form.groupname = "";
		}
	}
	
}
</script>

<style>
</style>
