<template>
	<b-modal
	id="editUsersinGroupModal"
	:hide-footer="true"
	:title="`Edit users in '${group?.name}'`"
	>
		<p>
			{{ selectedUsers.length }} users selected
		</p>

		<MultiSelect
			style="padding-bottom: 20px; margin-bottom: 20px;"
			v-model="selectedUsers"
			@input="usersUpdated = false;"
			:options="users || []"
			:multiple="true"
			:searchable="true"
			:show-label="false"
			:close-on-select="false"
			label="username"
			track-by="username"
			placeholder="Select users..."
		>
			<template #tag="{ option }">
				<span class="custom__tag" :class="{'currentUser': option.username == currentUser}">{{ option.username }}</span>
			</template>
		</MultiSelect>

		<hr />

		<div class="userManagement__modal__footer">
			<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
			<b-button v-else variant="primary" size="sm" :disabled="usersUpdated || reqStatus" @click="handleSubmit">Submit</b-button>
		</div>
	</b-modal>
</template>

<script>
import axios from 'axios';
import store from '../../store';

export default {
	name : "EditUsersInGroup",
	props : ["farm", "users"],
	data() {
		return { 
			currentUser: localStorage.getItem('user'),
			usersUpdated: true,
			selectedUsers : [],
			reqStatus: false
		}
	},
	watch : {
		group(newGroup){
			this.selectedUsers = newGroup?.users || [];
		},
		users(){
			this.selectedUsers = this.group?.users || [];
		}
	},
	computed:{
		group: function(){ return store.state.groupToManage; }
	},
	methods : {
		async handleSubmit(){
			this.reqStatus = true;
			const payload = {
					users: this.selectedUsers.map((su) => {return su._id}),
					groupId : this.group._id
				}

			axios.put(`/editUsersinGroup?farm=${this.farm}`, payload)
			.then((response) => {
				console.log(response.data);
				const res_grp_name = response.data.name;
				this.$emit('success', `updated group ${res_grp_name} in ${this.farm}`);
				this.$bvModal.hide("editUsersinGroupModal");
				this.reqStatus = false;
				this.selectedUsers = [];
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("editUsersinGroupModal");
				this.$emit('error', err.response.data.error);
				this.reqStatus = false;
				this.selectedUsers = [];
			})
		}
	}
}
</script>

<style>
.addUsersToGroupModal__searchInput{
	width: 100%;
	padding: 10px;
	border-radius: 5px;
}
span.custom__tag {
	margin: 0px 3px;
	padding: 5px 10px;
	border-radius: 20px;
	background-color: lightgreen;
}
span.custom__tag.currentUser {
	background-color: gold;
}
</style>