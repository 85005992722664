<template>
	<b-modal
		id="userPassResetModal"
		:hide-footer="true"
		:title="`Reset password for user '${user?.username}'`"
	>
		<p> Resetting password would send new password and instructions to user's email </p>
		<b-form @submit="onUpdateConfirm">
			<p>Please type in the username to confirm user password reset </p>
			<b-form-input
				v-model="form.username"
				type="text"
				:placeholder="user?.username"
				:pattern="user?.username"
				required
			></b-form-input>

			<hr />

			<div class="userManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" size="sm" variant="danger" :disabled="reqStatus">Reset</b-button>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
import axios from 'axios';
import store from '../../store';

export default {
	name : "UserPassResetModal",
	props : {
		farm : {type: String},
	},
	data(){
		return {
			form : { username: "" },
			reqStatus: false
		}
	},
	watch : {
		user(){
			this.form.username = "";
		}
	},
	computed:{
		user: function(){ return store.state.userToEdit; }
	},
	methods : {
		onUpdateConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			// Check username match
			if(this.form.username == this.user.username){
				axios.put(`/userManagement/user/reset/${this.user._id}`)
				.then((response) => {
					const res_user = response.data.username;
					this.$emit('success', `updated user ${res_user}`);
					this.$bvModal.hide("userPassResetModal");
					this.reqStatus = false;
				})
				.catch((err) => {
					console.error(err);
					this.$bvModal.hide("userPassResetModal");
					this.$emit('error', err.response.data.error);
					this.reqStatus = false;
				});
			}
		}
	}
}
</script>