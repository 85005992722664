<template>
	<b-modal
		id="editGroupModal"
		:hide-footer="true"
		:title="`Edit group ${group?.name} for ${farm}`"
	>
		<b-form @submit="onUpdateConfirm">
			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Name:"
				label-for="nameInput"
			>
				<b-form-input
					id="nameInput"
					v-model="form.name"
					type="text"
					placeholder="agronomist"
					@update="groupUpdated = false;"
					required
				></b-form-input>
			</b-form-group>

			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Permissions:"
				label-for="permInput"
			>
				<table class="table scroll" id="permInput">
					<tr><th>name</th><th>permissions</th><th>-</th></tr>
					<tbody>
						<tr v-for="(perm, epk) in form.permissions" :key="epk">
							<td>{{ epk }}</td>
							<td>
								<b-form-checkbox
									v-model="perm.r"
								>read</b-form-checkbox>
								<b-form-checkbox
									v-model="perm.w"
								>write</b-form-checkbox>
							</td>
							<td>
								<b-button size="sm" variant="danger" v-on:click="deletePermission(epk)"><i class="fas fa-trash"></i></b-button>
							</td>
						</tr>
						<tr> 
							<td colspan="2">
								<v-select
									:searchable="true"
									:options="routes"
									v-model="selectedRoute"
								/>
							</td>
							<td>
								<b-button size="sm" variant="warning" v-on:click="addPermission()">+</b-button>
							</td>
						</tr>
					</tbody>
				</table>
			</b-form-group>

			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Admin Group:"
				label-for="superUserCheckbox"
			>
				<b-form-checkbox
					id="superUserCheckbox"
					v-model="form.isSuperUser"
					name="superUserCheckbox"
					@change="groupUpdated = false;"
				>
					<p v-show="form.isSuperUser">
						grants all permissions by default
					</p>
				</b-form-checkbox>
			</b-form-group>

			<hr />

			<div class="userManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" variant="primary" :disabled="groupUpdated || reqStatus">Submit</b-button>
			</div>
		</b-form>

	</b-modal>
</template>

<script>
import axios from 'axios';
import store from '../../store';
import routeWhitelist from '../../assets/routeWhitelist.json';

export default {
	name: "EditGroupModal",
	props : {
		farm : {type: String},
	},
	data() {
		return  {
			form: {
				name: "",
				permissions: {},
				isSuperUser: false
			},
			selectedRoute: null,
			routes: routeWhitelist,
			groupUpdated: true,
			reqStatus: false
		}
	},
	watch : {
		group(newGroup){
			this.form =  {
				name: newGroup?.name,
				permissions: newGroup?.permissions || {},
				isSuperUser: newGroup?.isSuperUser
			}
		}
	},
	computed:{
		group: function(){ return store.state.groupToManage; }
	},
	methods: {
		onUpdateConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			axios.put(`/${this.farm}/group/${this.group._id}`, this.form)
			.then((response) => {
				console.log(response.data);
				const res_grp_name = response.data.name;
				this.$emit('success', `updated group ${res_grp_name} in ${this.farm}`);
				this.$bvModal.hide("editGroupModal");
				this.reqStatus = false;
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("editGroupModal");
				this.$emit('error', err.response.data.error);
				this.reqStatus = false;
			})
		},
		deletePermission(rName){
			this.groupUpdated = false;
			delete this.form.permissions[rName];
		},
		addPermission(){
			if(this.selectedRoute){
				console.log(this.form.permissions);
				this.form.permissions[this.selectedRoute] = {r: true, w: true};
				this.selectedRoute = null;
				this.groupUpdated = false;
			}
		}
	}
}
</script>

<style>
</style>
