<template>
	<b-modal
		id="addGroupModal"
		:hide-footer="true"
		:title="`Add group for ${farm}`"
	>
		<b-form @submit="onCreateConfirm">
			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Name:"
				label-for="nameInput"
			>
				<b-form-input
					id="nameInput"
					v-model="form.name"
					type="text"
					placeholder="agronomist"
					required
				></b-form-input>
			</b-form-group>

			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Permissions:"
				label-for="permInput"
			>
				<table class="table scroll" id="permInput">
					<tr><th>name</th><th>permissions</th><th>-</th></tr>
					<tbody>
						<tr v-for="(perm, apk) in form.permissions" :key="apk">
							<td>{{ apk }}</td>
							<td>
								<b-form-checkbox
									v-model="perm.r"
								>read</b-form-checkbox>
								<b-form-checkbox
									v-model="perm.w"
								>write</b-form-checkbox>
							</td>
							<td>
								<b-button size="sm" variant="danger" v-on:click="deletePermission(apk)"><i class="fas fa-trash"></i></b-button>
							</td>
						</tr>
						<tr> 
							<td colspan="2">
								<v-select
									:searchable="true"
									:options="routes"
									v-model="selectedRoute"
								/>
							</td>
							<td>
								<b-button size="sm" variant="warning" v-on:click="addPermission()">+</b-button>
							</td>
						</tr>
					</tbody>
				</table>
			</b-form-group>

			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Admin Group:"
				label-for="superUserCheckbox"
			>
				<b-form-checkbox
					id="superUserCheckbox"
					v-model="form.isSuperUser"
					name="superUserCheckbox"
				>
					<p v-show="form.isSuperUser">
						grants all permissions by default
					</p>
				</b-form-checkbox>
			</b-form-group>

			<hr />

			<div class="userManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" size="sm" variant="primary" :disabled="reqStatus">Submit</b-button>
			</div>
		</b-form>

	</b-modal>
</template>

<script>
import axios from 'axios';
import routeWhitelist from '../../assets/routeWhitelist.json';

export default {
	name: "AddGroupModal",
	props : {
		farm : {type: String},
	},
	data() {
		return  {
			form: {
				name: "",
				permissions: {"crops": {r: true, w: true}},
				isSuperUser: false
			},
			selectedRoute: null,
			routes: routeWhitelist,
			reqStatus: false
		}
	},
	methods : {
		onCreateConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			axios.post(`/${this.farm}/group`, this.form)
			.then((response) => {
				console.log(response.data)
				this.form = {
					name: "",
					permissions: "",
					isSuperUser: false
				};
				const res_grp_name = response.data.name;
				this.$emit('success', `added group ${res_grp_name} in ${this.farm}`);
				this.$bvModal.hide("addGroupModal");
				this.reqStatus = false;
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("addGroupModal");
				this.$emit('error', err.response.data.error);
				this.reqStatus = false;
			})
		},
		deletePermission(rName){
			console.log(rName);
			delete this.form.permissions[rName];
		},
		addPermission(){
			if(this.selectedRoute){
				this.form.permissions[this.selectedRoute] = {r: true, w: true};
				this.selectedRoute = null;
			}
		}
	}
}
</script>

<style>
</style>
