<template>
	<div class="groupCard">
		<div class="userManagement_card box-shadow-1">
			<div class="userManagement_card__info-col">
				<h4>{{ group.name }} <i v-show="group.isSuperUser" :style="{color:'gold'}" class="fas fa-crown"></i> </h4>
				<ul class="group__userList"><li v-for="(user, uk) in users" :key="uk" :class="{'currentUser': user ==  currentUser}">{{ user }}</li></ul>
				<p> {{ group.users.length }} member(s)</p>
			</div>
			<div class="userManagement_card__btn-col">
				<!--Add users to group-->
				<b-button v-b-modal.editUsersinGroupModal @click="setGroupToManage" size="sm" variant="warning">
					<i class="fas fa-user-edit"></i> 
				</b-button>

				<!-- edit group information-->
				<b-button v-b-modal.editGroupModal @click="setGroupToManage" size="sm" variant="primary">
					<i class="fas fa-edit"></i>
				</b-button>

				<!-- delete  group-->
				<b-button v-b-modal.deleteGroupModal @click="setGroupToManage" size="sm" variant="danger">
					<i class="fas fa-trash"></i>
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name : "GroupCard",
	props : {
		group : {type: Object}
	},
	data(){
		return {
			users: this.group.users.map((u) => u.username),
			currentUser: localStorage.getItem('user')
		}
	},
	methods : {
		setGroupToManage(){
			this.$store.commit('setGroupToManage', this.group)
		}
	}
}
</script>

<style>
.group__userList {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.group__userList li {
	display: inline;
	border-radius: 20px;
	background-color: lightgreen;
	text-align: center;
	margin: 0px 3px;
	padding: 5px 10px;
	max-width: 100px;
}
.group__userList li.currentUser {
	background-color: lightgoldenrodyellow;
}
</style>