<template>
	<b-modal
		id="addUserModal"
		:hide-footer="true"
		:title="`Create User`"
	>
		<p>
			User will be generated with email and username below. Password will be emailed to user with instructions.			
		</p>
		<b-form @submit="onUpdateConfirm">
			<b-form-group
				id="usernameGroup"
				label="Username:"
				label-for="usernameInput"
			>
				<b-form-input
					id="usernameInput"
					v-model="form.username"
					type="text"
					placeholder="johndoe"
					required
				></b-form-input>
			</b-form-group>

			<b-form-group
				id="emailGroup"
				label="Email:"
				label-for="emailInput"
			>
				<b-form-input
					id="emailInput"
					v-model="form.email"
					type="email"
					placeholder="johndoe@gmail.com"
					required
				></b-form-input>
			</b-form-group>

			<hr />

			<div class="userManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" size="sm" variant="primary" :disabled="reqStatus">Submit</b-button>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
import axios from 'axios';
export default {
	name : "AddUserModal",
	props : {
		farm : {type: String},
	},
	data(){
		return {
			form : {
				username: "",
				email: ""
			},
			reqStatus: false
		}
	},
	methods : {
		onUpdateConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			axios.post(`/userManagement/user`, this.form)
			.then((response) => {
				const res_user = response.data.username;
				this.$emit('success', `added user ${res_user}`);
				this.$bvModal.hide("addUserModal");
				this.reqStatus = false;
				this.form = { username: "", email: "" };
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("addUserModal");
				this.$emit('error', err.response.data.error);
				this.reqStatus = false;
				this.form = { username: "", email: "" };
			});
		}
	},
}
</script>

<style>
</style>
